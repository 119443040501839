import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import Grid from '@mui/material/Grid';
import Layout from '../components/Layout';
import { styled } from '@mui/material/styles';

const StaffInfo = ({name, imageElement, role}) => 
        <Grid xs={12} md={4}>
            <div style={{height:'385px', width:'300px', border:'1px solid gray', marginBottom:'20px', backgroundColor:'white', fontSize:'1.2em', whiteSpace: 'pre-line'}} >
                {imageElement}
                <h1 style={{fontSize:'1em', wordWrap:'break-word', display:'block', alignContent:'center', marginLeft:'20px', width:'280px', marginTop:'10px'}}><span style={{color:'#3399f3'}}><b>{name}</b></span></h1>
                <p style={{fontSize:'0.9em', wordWrap:'break-word', display:'block', alignContent:'center', marginLeft:'20px', width:'280px', marginTop:'10px'}}>{role}</p>
            </div>
        </Grid>

const LeadershipTeamInfo = ({name, imageElement, role, website}) => 
        <Grid xs={12} md={4}>
            <div style={{height:'400px', width:'300px', border:'1px solid gray', marginBottom:'20px', backgroundColor:'white', fontSize:'1.2em', overflow:'hidden', overflowWrap:'break-word', wordBreak: 'break-all', whiteSpace: 'pre-line', display:'block' }} >
                {imageElement}
                <h1 style={{fontSize:'1em', wordWrap:'break-word', alignContent:'center', marginLeft:'20px', width:'280px', marginTop:'10px', overflow:'hidden'}}><span style={{color:'#3399f3'}}><b>{ website ? <a href={website} target='_blank' rel='noopener noreferrer' className='staffNameLink'>{name}</a> : <React.Fragment>{name}</React.Fragment>}</b></span></h1>
                <p style={{fontSize:'0.9em', wordWrap:'break-word', alignContent:'center', marginLeft:'20px', width:'280px', marginTop:'10px'}}>{role}</p>
            </div>    
        </Grid>

const localStaff = [
    {
        name: 'Irma Mireles',
        imageElement: <StaticImage src="../images/staff/irma_mireles.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Field Interviewer'
    },{
        name: 'Noemi Ybarra',
        imageElement: <StaticImage src="../images/staff/noemi_ybarra.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Field Interviewer'
    },{
        name: 'Nelda Garcia',
        imageElement: <StaticImage src="../images/staff/nelda_garcia.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Project Manager'
    },{
        name: 'Mercedes Fonseca',
        imageElement: <StaticImage src="../images/staff/mercedes_fonseca.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Field Interviewer'
    },{
        name: 'Carmen Ortiz',
        imageElement: <StaticImage src="../images/staff/Carmen Ortiz_Field Interviewer.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Field Interviewer'
    }
];

const studyLeadershipTeam = [
    {
        name: 'Lewis Morgenstern, MD',
        imageElement: <StaticImage src="../images/leadership/lewis_morgenstern.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Principal Investigator'
    },{
        name: 'Darin Zahuranec, MD, MS',
        imageElement: <StaticImage src="../images/leadership/darin_zahuranec.jpeg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Co-Investigator',
        website: 'https://medicine.umich.edu/dept/neurology/darin-zahuranec-md-ms'
    },{
        name: 'Emily Briceno-Abreu, PhD',
        imageElement: <StaticImage src="../images/leadership/emily_briceno_abreu.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Co-Investigator'
    },{
        name: 'Xavier Gonzales, PhD MSPH',
        imageElement: <StaticImage src="../images/leadership/xavier_gonzales.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Co-Investigator & Faculty at Texas A&M University Corpus Christi'
    },{
        name: 'Steven Heeringa, PhD',
        imageElement: <StaticImage src="../images/leadership/steven_heeringa.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Co-Investigator'
    },{
        name: 'Kenneth Langa, MD PhD',
        imageElement: <StaticImage src="../images/leadership/kenneth_langa.jpeg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Co-Investigator'
    },{
        name: 'Deborah Levine, MD MPH',
        imageElement: <StaticImage src="../images/leadership/deborah_levine.jpg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Co-Investigator'
    },{
        name: 'Roshanak Mehdipanah, PhD',
        imageElement: <StaticImage src="../images/leadership/roshanak-mehdipanah.jpeg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Co-Investigator'
    },{
        name: 'Wen Chang',
        imageElement: <StaticImage src="../images/leadership/female.jpeg" alt='Person Name' width='300' height='300' placeholder='blurred' />,
        role: 'Data analyst'
    }
]

export default function MeetOurTeam(){
    return (
        <Layout className='faqContent'>
            <div>
            <h1 style={{color:'#3399f3', marginBottom:'30px'}}>Who we are</h1>
            <p style={{fontSize:'1.2em'}}>If your address was one of the randomly selected households to receive our letter 
            in the mail, one of our friendly field interviewers below may be by to answer any questions 
            you or your study partner may have about participating.</p>
            <h2><a href="#local-staff" id="local-staff" className='callLink'>Local staff</a></h2>
            <div>
            <Grid container spacing={5} style={{marginLeft:'15px', marginTop:'5px'}}  className='faqContent' justifyContent="center" alignItems="center">
                {
                    localStaff.map(staffMember => 
                    <StaffInfo 
                        name = {staffMember.name}
                        imageElement = {staffMember.imageElement}
                        role = {staffMember.role}
                    />)
                }
            </Grid>
            </div>
            <h2><a href="#study-leardership" id="study-leardership" className='callLink'>Study Leadership</a></h2>
            <div>
            <Grid container spacing={5} style={{marginLeft:'15px', marginTop:'5px'}}  className='faqContent' justifyContent="center" alignItems="center">
                {
                    studyLeadershipTeam.map(staffMember => 
                    <LeadershipTeamInfo 
                        name = {staffMember.name}
                        imageElement = {staffMember.imageElement}
                        role = {staffMember.role}
                        website = {staffMember.website}
                    />)
                }
            </Grid>
            </div>
            </div>
        </Layout>
    )
}